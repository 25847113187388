<template>
  <div class="result" v-if="result">
    <h2>MBTI 테스트 결과</h2>
    <h3>당신의 MBTI 유형: {{ result }}</h3>
    <p class="description">{{ mbtiDescription.description }}</p>
    <div class="mbti-graph">
      <div class="graph-row" v-for="pair in graphData" :key="pair.label">
        <div class="graph-label">{{ pair.label }}</div>
        <div class="graph-bar">
          <div class="bar left" :style="{ width: `${pair.left}%` }">{{ pair.leftLabel }}</div>
          <div class="bar right" :style="{ width: `${pair.right}%` }">{{ pair.rightLabel }}</div>
        </div>
        <div class="graph-percentage">{{ pair.left }}% - {{ pair.right }}%</div>
      </div>
    </div>
    <div v-if="mbtiDescription" class="mbti-details">
      <div class="strengths">
        <h4>당신의 슈퍼파워:</h4>
        <ul>
          <li v-for="strength in mbtiDescription.strengths" :key="strength">{{ strength }}</li>
        </ul>
      </div>
      <div class="weaknesses">
        <h4>개선이 필요한 부분:</h4>
        <ul>
          <li v-for="weakness in mbtiDescription.weaknesses" :key="weakness">{{ weakness }}</li>
        </ul>
      </div>
      <div class="prescription">
        <h4>성장을 위한 처방전:</h4>
        <ul>
          <li v-for="prescription in mbtiDescription.prescription" :key="prescription">{{ prescription }}</li>
        </ul>
      </div>
    </div>
    <div class="button-group">
      <button @click="retakeTest">테스트 다시하기</button>
      <button @click="shareResult">결과 공유하기</button>
      <button @click="goToTestList">테스트 목록으로</button>
    </div>
  </div>
  <div v-else class="loading">
    결과를 불러오는 중입니다...
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MBTITestResult',
  props: {
    result: String,
    scores: Object,
  },
  computed: {
    ...mapState(['mbtiDescriptions']),
    mbtiDescription() {
      return this.mbtiDescriptions[this.result];
    },
    graphData() {
      return [
        { label: 'E - I', leftLabel: 'E', rightLabel: 'I', left: this.scores.E, right: this.scores.I },
        { label: 'S - N', leftLabel: 'S', rightLabel: 'N', left: this.scores.S, right: this.scores.N },
        { label: 'T - F', leftLabel: 'T', rightLabel: 'F', left: this.scores.T, right: this.scores.F },
        { label: 'J - P', leftLabel: 'J', rightLabel: 'P', left: this.scores.J, right: this.scores.P },
      ];
    },
  },
  methods: {
    ...mapActions(['startTest', 'resetTest']),
    retakeTest() {
      this.startTest('MBTI');
    },
    shareResult() {
      const shareText = `
내 MBTI 유형은 ${this.result}입니다! (${this.mbtiDescription.description})

E-I: ${this.scores.E}%-${this.scores.I}%
S-N: ${this.scores.S}%-${this.scores.N}%
T-F: ${this.scores.T}%-${this.scores.F}%
J-P: ${this.scores.J}%-${this.scores.P}%

슈퍼파워:
${this.mbtiDescription.strengths.join('\n')}

개선이 필요한 부분:
${this.mbtiDescription.weaknesses.join('\n')}

성장을 위한 처방전:
${this.mbtiDescription.prescription.join('\n')}

나도 테스트 해보기: ${window.location.origin}/mbti-test
      `;
      
      if (navigator.share) {
        navigator.share({
          title: 'MBTI 테스트 결과',
          text: shareText,
          url: window.location.href,
        })
          .then(() => console.log('공유 성공'))
          .catch((error) => console.log('공유 실패:', error));
      } else {
        const textarea = document.createElement('textarea');
        textarea.value = shareText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('결과가 클립보드에 복사되었습니다. 원하는 곳에 붙여넣기 하세요.');
      }
    },
    goToTestList() {
      this.resetTest();
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss">
.result {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: background-color 0.3s, color 0.3s;
}

body:not(.dark-mode) .result {
  background-color: #f8f9fa;
  color: #343a40;
}

body.dark-mode .result {
  background-color: #2c3e50;
  color: #ecf0f1;
}

h2, h3, h4 {
  margin-top: 20px;
  transition: color 0.3s;
}

body:not(.dark-mode) h2, body:not(.dark-mode) h3, body:not(.dark-mode) h4 {
  color: #343a40;
}

body.dark-mode h2, body.dark-mode h3, body.dark-mode h4 {
  color: #ecf0f1;
}

.description {
  font-size: 1.2em;
  font-style: italic;
  margin-bottom: 20px;
  transition: color 0.3s;
}

body:not(.dark-mode) .description {
  color: #6c757d;
}

body.dark-mode .description {
  color: #bdc3c7;
}

.mbti-graph {
  margin: 30px 0;
}

.graph-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.graph-label {
  width: 50px;
  text-align: right;
  margin-right: 10px;
  font-weight: bold;
}

.graph-bar {
  flex-grow: 1;
  height: 30px;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
  transition: background-color 0.3s;
}

body:not(.dark-mode) .graph-bar {
  background-color: #e9ecef;
}

body.dark-mode .graph-bar {
  background-color: #34495e;
}

.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  transition: width 0.5s ease-out, background-color 0.3s;
}

body:not(.dark-mode) .bar.left {
  background-color: #007bff;
}

body:not(.dark-mode) .bar.right {
  background-color: #28a745;
}

body.dark-mode .bar.left {
  background-color: #3498db;
}

body.dark-mode .bar.right {
  background-color: #2ecc71;
}

.graph-percentage {
  width: 100px;
  margin-left: 10px;
}

.mbti-details {
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

body:not(.dark-mode) .mbti-details {
  background-color: white;
}

body.dark-mode .mbti-details {
  background-color: #34495e;
  box-shadow: 0 2px 4px rgba(255,255,255,0.1);
}

.strengths, .weaknesses, .prescription {
  margin-bottom: 20px;
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

.button-group {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

body:not(.dark-mode) button {
  background-color: #007bff;
  color: white;
}

body:not(.dark-mode) button:hover {
  background-color: #0056b3;
}

body.dark-mode button {
  background-color: #3498db;
  color: #ecf0f1;
}

body.dark-mode button:hover {
  background-color: #2980b9;
}

.loading {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}
</style>