<template>
  <div class="result">
    <h2>IQ 테스트 결과</h2>
    <h3>당신의 IQ 점수: {{ score }}</h3>
    <p>정답률: {{ percentageScore }}%</p>
    <p class="description">{{ description }}</p>
    <div class="advice">
      <h4>개선을 위한 조언:</h4>
      <ul>
        <li v-for="(advice, index) in advices" :key="index">{{ advice }}</li>
      </ul>
    </div>
    <div class="button-group">
      <button @click="retakeTest">테스트 다시하기</button>
      <button @click="shareResult">결과 공유하기</button>
      <button @click="goToTestList">테스트 목록으로</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'IQTestResult',
  props: {
    score: Number,
    percentageScore: Number,
  },
  computed: {
    description() {
      if (this.score < 80) return "평균 이하의 점수입니다. IQ는 지능의 한 측면일 뿐이며, 다양한 종류의 지능이 존재합니다.";
      if (this.score < 90) return "평균에 가까운 점수입니다. 지속적인 학습과 연습으로 능력을 향상시킬 수 있습니다.";
      if (this.score < 110) return "평균적인 점수입니다. 대부분의 사람들이 이 범위에 속합니다.";
      if (this.score < 120) return "평균 이상의 점수입니다. 당신은 복잡한 문제를 해결하는 데 능숙할 수 있습니다.";
      return "매우 높은 점수입니다. 당신은 복잡한 개념을 빠르게 이해하고 적용할 수 있는 능력이 있습니다.";
    },
    advices() {
      if (this.score < 90) return [
        "독서를 통해 지식을 넓히세요.",
        "퍼즐이나 논리 게임을 즐겨보세요.",
        "새로운 기술을 배워보는 것도 좋습니다."
      ];
      if (this.score < 110) return [
        "관심 있는 주제에 대해 깊이 있게 공부해보세요.",
        "비판적 사고 능력을 기르는 활동을 해보세요.",
        "다양한 분야의 사람들과 대화를 나누어보세요."
      ];
      return [
        "더 어려운 문제에 도전해보세요.",
        "다른 사람들을 가르치거나 멘토링해보는 것도 좋습니다.",
        "다양한 분야의 지식을 통합하는 능력을 키워보세요."
      ];
    }
  },
  methods: {
    ...mapActions(['startTest', 'resetTest']),
    retakeTest() {
      this.startTest('IQ');
    },
    shareResult() {
      const shareText = `내 IQ 점수는 ${this.score}입니다! (정답률: ${this.percentageScore}%)\n${this.description}\n\n나도 테스트 해보기: ${window.location.origin}/iq-test`;
      
      if (navigator.share) {
        navigator.share({
          title: 'IQ 테스트 결과',
          text: shareText,
          url: window.location.href,
        })
          .then(() => console.log('공유 성공'))
          .catch((error) => console.log('공유 실패:', error));
      } else {
        const textarea = document.createElement('textarea');
        textarea.value = shareText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('결과가 클립보드에 복사되었습니다. 원하는 곳에 붙여넣기 하세요.');
      }
    },
    goToTestList() {
      this.resetTest();
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss">
.result {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: background-color 0.3s, color 0.3s;
}

body:not(.dark-mode) .result {
  background-color: #f8f9fa;
  color: #343a40;
}

body.dark-mode .result {
  background-color: #2c3e50;
  color: #ecf0f1;
  box-shadow: 0 0 10px rgba(255,255,255,0.1);
}

h2, h3, h4 {
  margin-top: 20px;
  transition: color 0.3s;
}

body:not(.dark-mode) h2, body:not(.dark-mode) h3, body:not(.dark-mode) h4 {
  color: #343a40;
}

body.dark-mode h2, body.dark-mode h3, body.dark-mode h4 {
  color: #ecf0f1;
}

.percentile {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.description {
  margin-bottom: 20px;
  transition: color 0.3s;
}

body:not(.dark-mode) .description {
  color: #6c757d;
}

body.dark-mode .description {
  color: #bdc3c7;
}

.advice {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: background-color 0.3s, color 0.3s;
}

body:not(.dark-mode) .advice {
  background-color: #e9ecef;
  color: #343a40;
}

body.dark-mode .advice {
  background-color: #34495e;
  color: #ecf0f1;
}

ul {
  padding-left: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

body:not(.dark-mode) button {
  background-color: #007bff;
  color: white;
}

body:not(.dark-mode) button:hover {
  background-color: #0056b3;
}

body.dark-mode button {
  background-color: #3498db;
  color: #ecf0f1;
}

body.dark-mode button:hover {
  background-color: #2980b9;
}
</style>