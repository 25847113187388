import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { inject } from '@vercel/analytics'
import { SpeedInsights } from "@vercel/speed-insights/vue"

inject()

createApp(App)
  .use(router)
  .use(store)
  .use(SpeedInsights)
  .mount('#app')