<template>
  <div class="iq-test">
    <h1>IQ 테스트</h1>
    <div v-if="!testCompleted" class="timer">남은 시간: {{ formatTime(remainingTime) }}</div>
    <div v-if="!testCompleted" class="progress-bar">
      <div class="progress" :style="{ width: `${progress}%` }"></div>
    </div>
    <p v-if="!testCompleted" class="progress-text">{{ currentQuestionIndex + 1 }} / {{ totalQuestions }}</p>
    <div v-if="!testCompleted && currentQuestion" class="question-container">
      <h2>{{ currentQuestion.question }}</h2>
      <div v-if="currentQuestion.type === 'table'" class="pattern-container table-container">
        <table class="pattern-table">
          <tr v-for="(row, rowIndex) in currentQuestion.pattern" :key="rowIndex">
            <td v-for="(cell, cellIndex) in row.split(' ')" :key="cellIndex">
              {{ cell }}
            </td>
          </tr>
        </table>
      </div>
      <div v-else-if="currentQuestion.type === 'svg'" class="pattern-container svg-container" v-html="currentQuestion.pattern"></div>
      <div v-else-if="currentQuestion.type === 'text'" class="pattern-container text-container">
        <p v-for="(line, index) in currentQuestion.pattern" :key="index">{{ line }}</p>
      </div>
      <div class="options" :class="{ 'svg-options': currentQuestion.type === 'svg' }">
        <button 
          v-for="(option, index) in currentQuestion.options" 
          :key="index"
          @click="selectAnswer(index)"
          :class="{ selected: answers[currentQuestion.id] === index }"
          :aria-label="`옵션 ${index + 1}: ${getOptionDescription(option)}`"
        >
          <span v-if="currentQuestion.type === 'svg'" v-html="option"></span>
          <span v-else>{{ option }}</span>
        </button>
      </div>
    </div>
    <div v-if="!testCompleted" class="navigation">
      <button @click="prevQuestion" :disabled="currentQuestionIndex === 0">이전</button>
      <button @click="nextQuestion" :disabled="!canProceed">
        {{ isLastQuestion ? '결과 보기' : '다음' }}
      </button>
    </div>
    <IQTestResult 
      v-if="testCompleted"
      :score="testResult.score"
      :percentageScore="testResult.percentageScore"
    />
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import IQTestResult from '@/components/IQTestResult.vue';

export default {
  name: 'IQTest',
  components: {
    IQTestResult
  },
  data() {
    return {
      remainingTime: 0,
      timer: null,
      timePerQuestion: 90, // 1분 30초 (초 단위)
    }
  },
  computed: {
    ...mapState(['currentQuestionIndex', 'answers', 'iqQuestions', 'testCompleted', 'testResult']),
    ...mapGetters(['currentQuestion', 'isLastQuestion']),
    canProceed() {
      return this.currentQuestion && this.answers[this.currentQuestion.id] !== undefined;
    },
    progress() {
      return (this.currentQuestionIndex / this.totalQuestions) * 100;
    },
    totalQuestions() {
      return this.iqQuestions.length;
    },
    totalTime() {
      return this.totalQuestions * this.timePerQuestion;
    }
  },
  methods: {
    ...mapActions(['startTest', 'answerQuestion', 'nextQuestion', 'prevQuestion', 'completeTest']),
    selectAnswer(index) {
      this.answerQuestion({ questionId: this.currentQuestion.id, value: index });
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    },
    startTimer() {
      this.remainingTime = this.totalTime;
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          this.endTest();
        }
      }, 1000);
    },
    endTest() {
      clearInterval(this.timer);
      this.fillUnansweredQuestions();
      this.completeTest();
    },
    fillUnansweredQuestions() {
      this.iqQuestions.forEach(question => {
        if (this.answers[question.id] === undefined) {
          this.answerQuestion({ questionId: question.id, value: -1 }); // -1은 답변하지 않은 상태를 나타냅니다
        }
      });
    },
    nextQuestion() {
      if (this.isLastQuestion) {
        this.endTest();
      } else {
        this.$store.dispatch('nextQuestion');
      }
    },
    getOptionDescription(option) {
      const descriptions = {
        '★': '꽉 찬 별',
        '☆': '빈 별',
        '♥': '꽉 찬 하트',
        '♡': '빈 하트',
        '■': '꽉 찬 사각형',
        '□': '빈 사각형',
        '●': '꽉 찬 원',
        '○': '빈 원',
        '▲': '꽉 찬 삼각형',
        '△': '빈 삼각형'
      };
      return descriptions[option] || option;
    }
  },
  created() {
    this.startTest('IQ');
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
}
</script>

<style scoped lang="scss">
.iq-test {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: var(--text-color);
  background-color: var(--bg-color);
}

.timer {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: var(--text-color);
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: var(--progress-bg-color);
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: var(--progress-color);
  transition: width 0.3s ease;
}

.progress-text {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.question-container {
  margin-bottom: 20px;
  background-color: var(--question-bg-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.pattern-container {
  width: 100%;
  margin: 20px auto;
  background-color: var(--pattern-bg-color);
  padding: 10px;
  border-radius: 5px;
  box-shadow: inset 0 2px 4px var(--shadow-color);
  overflow-x: auto;
}

.table-container {
  max-width: 100%;
}

.pattern-table {
  margin: 0 auto;
  border-collapse: collapse;
  
  td {
    border: 1px solid var(--border-color);
    padding: 10px;
    text-align: center;
    font-size: 1.5em;
    min-width: 40px;
  }
}

.svg-container {
  text-align: center;

  svg {
    max-width: 100%;
    height: auto;
  }
}

.text-container {
  text-align: left;
  font-size: 1.2em;
  line-height: 1.5;
}

.options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  &.svg-options {
    grid-template-columns: repeat(2, 1fr);
  }
}

button {
  padding: 15px;
  background-color: var(--button-bg-color);
  border: 2px solid var(--button-border-color);
  color: var(--button-text-color);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--button-hover-bg-color);
  }

  &.selected {
    background-color: var(--button-selected-bg-color);
    color: var(--button-selected-text-color);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--focus-color);
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
      max-width: 100%;
      max-height: 100px;
    }
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .options {
    grid-template-columns: 1fr;
  }

  .options.svg-options {
    grid-template-columns: 1fr;
  }
}
</style>