import { createStore } from 'vuex'
import mbtiQuestions from '@/data/mbti_questions.json'
import adhdQuestions from '@/data/adhd_questions.json'
import iqQuestions from '@/data/iq_questions.json'
import mbtiDescriptions from '@/data/mbti_descriptions.json'
import iqDescriptions from '@/data/iq_descriptions.json'

export default createStore({
  state: {
    mbtiQuestions,
    adhdQuestions,
    iqQuestions,
    mbtiDescriptions,
    iqDescriptions,
    currentTest: null,
    currentQuestionIndex: 0,
    answers: {},
    testCompleted: false,
    testResult: null, // 추가
  },
  mutations: {
    setCurrentTest(state, testType) {
      state.currentTest = testType;
      state.currentQuestionIndex = 0;
      state.answers = {};
      state.testCompleted = false;
      state.testResult = null; // 초기화
    },
    setAnswer(state, { questionId, value }) {
      state.answers = { ...state.answers, [questionId]: value };
    },
    nextQuestion(state) {
      const questions = state.currentTest === 'MBTI' ? state.mbtiQuestions : 
                        state.currentTest === 'ADHD' ? state.adhdQuestions : state.iqQuestions;
      if (state.currentQuestionIndex < questions.length - 1) {
        state.currentQuestionIndex++;
      }
    },
    prevQuestion(state) {
      if (state.currentQuestionIndex > 0) {
        state.currentQuestionIndex--;
      }
    },
    completeTest(state) {
      state.testCompleted = true;
    },
    setTestResult(state, result) {
      state.testResult = result;
    }
  },
  actions: {
    startTest({ commit }, testType) {
      commit('setCurrentTest', testType);
    },
    answerQuestion({ commit, dispatch, getters }, { questionId, value }) {
      commit('setAnswer', { questionId, value });
      if (getters.isLastQuestion) {
        dispatch('completeTest');
      } else {
        dispatch('nextQuestion');
      }
    },
    nextQuestion({ commit }) {
      commit('nextQuestion');
    },
    prevQuestion({ commit }) {
      commit('prevQuestion');
    },
    completeTest({ commit, getters }) {
      commit('completeTest');
      const result = getters.testResult; // testResult getter를 호출하여 결과 계산
      commit('setTestResult', result);
    }
  },
  getters: {
    currentQuestion(state) {
      const questions = state.currentTest === 'MBTI' ? state.mbtiQuestions : 
                        state.currentTest === 'ADHD' ? state.adhdQuestions : state.iqQuestions;
      return questions[state.currentQuestionIndex];
    },
    isLastQuestion(state) {
      const questions = state.currentTest === 'MBTI' ? state.mbtiQuestions : 
                        state.currentTest === 'ADHD' ? state.adhdQuestions : state.iqQuestions;
      return state.currentQuestionIndex === questions.length - 1;
    },
    isTestCompleted(state) {
      return state.testCompleted;
    },
    testResult(state) {
      console.log('Calculating test result. Current test:', state.currentTest);
      console.log('Answers:', state.answers);

      if (state.currentTest === 'MBTI') {
        const scores = { E: 0, I: 0, S: 0, N: 0, T: 0, F: 0, J: 0, P: 0 };
        const maxScores = { EI: 0, SN: 0, TF: 0, JP: 0 };
        
        Object.entries(state.answers).forEach(([questionId, value]) => {
          const question = state.mbtiQuestions.find(q => q.id === parseInt(questionId));
          if (question && question.weights) {
            Object.entries(question.weights).forEach(([trait, weight]) => {
              scores[trait] += weight * (value - 3);
              if (['E', 'I'].includes(trait)) maxScores.EI += Math.abs(weight) * 2;
              if (['S', 'N'].includes(trait)) maxScores.SN += Math.abs(weight) * 2;
              if (['T', 'F'].includes(trait)) maxScores.TF += Math.abs(weight) * 2;
              if (['J', 'P'].includes(trait)) maxScores.JP += Math.abs(weight) * 2;
            });
          }
        });

        const percentages = {
          E: Math.round(((scores.E + maxScores.EI / 2) / maxScores.EI) * 100),
          I: Math.round(((scores.I + maxScores.EI / 2) / maxScores.EI) * 100),
          S: Math.round(((scores.S + maxScores.SN / 2) / maxScores.SN) * 100),
          N: Math.round(((scores.N + maxScores.SN / 2) / maxScores.SN) * 100),
          T: Math.round(((scores.T + maxScores.TF / 2) / maxScores.TF) * 100),
          F: Math.round(((scores.F + maxScores.TF / 2) / maxScores.TF) * 100),
          J: Math.round(((scores.J + maxScores.JP / 2) / maxScores.JP) * 100),
          P: Math.round(((scores.P + maxScores.JP / 2) / maxScores.JP) * 100)
        };

        const result = [
          percentages.E > 50 ? 'E' : 'I',
          percentages.S > 50 ? 'S' : 'N',
          percentages.T > 50 ? 'T' : 'F',
          percentages.J > 50 ? 'J' : 'P'
        ].join('');

        console.log('MBTI result:', { type: result, scores: percentages });
        return { type: result, scores: percentages };
      } else if (state.currentTest === 'ADHD') {
        const scores = { inattentive: 0, hyperactive: 0, combined: 0 };
        const maxScores = { inattentive: 0, hyperactive: 0, combined: 0 };
        
        Object.entries(state.answers).forEach(([questionId, value]) => {
          const question = state.adhdQuestions.find(q => q.id === parseInt(questionId));
          if (question) {
            Object.entries(question.weights).forEach(([type, weight]) => {
              scores[type] += weight * value;
              maxScores[type] += weight * 5; // 5는 최대 점수
            });
          }
        });
        
        // 백분율로 변환
        const percentScores = {
          inattentive: Math.round((scores.inattentive / maxScores.inattentive) * 100),
          hyperactive: Math.round((scores.hyperactive / maxScores.hyperactive) * 100),
          combined: Math.round((scores.combined / maxScores.combined) * 100)
        };
        
        console.log('ADHD result:', percentScores);
        return percentScores;
      } else if (state.currentTest === 'IQ') {
        let totalScore = 0;
        let answeredQuestions = 0;
        const totalQuestions = state.iqQuestions.length;
      
        state.iqQuestions.forEach((question) => {
          const userAnswer = state.answers[question.id];
          if (userAnswer !== undefined && userAnswer !== -1) {
            answeredQuestions++;
            const distance = Math.abs(userAnswer - question.correctAnswer);
            let score;
            switch (distance) {
              case 0: score = 5; break; // 정답
              case 1: score = 3; break; // 정답에 가까움
              case 2: score = 1; break; // 약간 틀림
              default: score = 0; // 완전히 틀림
            }
            totalScore += score;
          }
        });
      
        const percentageScore = answeredQuestions > 0 
          ? Math.round((totalScore / (answeredQuestions * 5)) * 100) 
          : 0;
        const iqScore = Math.round(70 + (percentageScore / 100) * 60); // IQ 범위를 70-130으로 설정
      
        const result = {
          score: iqScore,
          percentageScore,
          answeredQuestions,
          totalQuestions
        };
        console.log('IQ result:', result);
        return result;
      }
      return null;
    },
  },
})