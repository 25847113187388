import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MBTITest from '../views/MBTITest.vue'
import ADHDTest from '../views/ADHDTest.vue'
import IQTest from '../views/IQTest.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mbti-test',
    name: 'MBTITest',
    component: MBTITest
  },
  {
    path: '/adhd-test',
    name: 'ADHDTest',
    component: ADHDTest
  },
  {
    path: '/iq-test',
    name: 'IQTest',
    component: IQTest
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router