<template>
  <div class="question">
    <h2>{{ question.text }}</h2>
    <div class="options">
      <button 
        v-for="option in options" 
        :key="option.value" 
        @click="selectAnswer(option.value)"
        :class="{ selected: selectedAnswer === option.value }"
      >
        {{ option.text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestQuestion',
  props: {
    question: Object,
    answer: Number,
  },
  data() {
    return {
      options: [
        { value: 5, text: '매우 그렇다' },
        { value: 4, text: '그렇다' },
        { value: 3, text: '보통이다' },
        { value: 2, text: '아니다' },
        { value: 1, text: '전혀 아니다' },
      ],
    }
  },
  computed: {
    selectedAnswer: {
      get() {
        return this.answer;
      },
      set(value) {
        this.$emit('answer', this.question.id, value);
      }
    }
  },
  methods: {
    selectAnswer(value) {
      this.selectedAnswer = value;
    },
  },
}
</script>

<style scoped lang="scss">
.question {
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

button {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  cursor: pointer;

  &.selected {
    background-color: #007bff;
    color: white;
  }
}
</style>