<template>
  <div id="app" :class="{ 'dark-mode': isDarkMode }">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/mbti-test">MBTI Test</router-link> |
      <router-link to="/adhd-test">ADHD Test</router-link> |
      <router-link to="/iq-test">IQ Test</router-link>
      <button @click="toggleDarkMode" class="mode-toggle">
        {{ isDarkMode ? '라이트 모드' : '다크 모드' }}
      </button>
    </nav>
    <router-view/>
    <SpeedInsights />
  </div>
</template>

<script>
import { SpeedInsights } from "@vercel/speed-insights/vue";

export default {
  name: 'App',
  data() {
    return {
      isDarkMode: false
    }
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      localStorage.setItem('darkMode', this.isDarkMode);
      document.body.classList.toggle('dark-mode', this.isDarkMode);
    }
  },
  created() {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
      this.isDarkMode = JSON.parse(savedMode);
      document.body.classList.toggle('dark-mode', this.isDarkMode);
    }
  },
  components: {
    SpeedInsights,
  },
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  transition: background-color 0.3s, color 0.3s;
}

body.dark-mode {
  background-color: #2c3e50;
  color: #ecf0f1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s;
}

body.dark-mode nav a {
  color: #ecf0f1;
}

nav a.router-link-exact-active {
  color: #42b983;
}

body.dark-mode nav a.router-link-exact-active {
  color: #2ecc71;
}

.mode-toggle {
  background-color: #34495e;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-left: 20px;
}

body.dark-mode .mode-toggle {
  background-color: #ecf0f1;
  color: #2c3e50;
}

.mode-toggle:hover {
  background-color: #2c3e50;
  color: #ffffff;
}

body.dark-mode .mode-toggle:hover {
  background-color: #bdc3c7;
  color: #2c3e50;
}

/* 다크모드에서의 전역 스타일 */
body.dark-mode h1, 
body.dark-mode h2, 
body.dark-mode h3, 
body.dark-mode p {
  color: #ecf0f1;
}

body.dark-mode .test-card {
  background-color: #34495e;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

body.dark-mode .test-card h2, 
body.dark-mode .test-card p {
  color: #ecf0f1;
}

body.dark-mode .start-button {
  background-color: #2980b9;
  color: #ffffff;
}

body.dark-mode .start-button:hover {
  background-color: #3498db;
}

body.dark-mode .footer {
  color: #bdc3c7;
}

body.dark-mode .footer .disclaimer {
  color: #95a5a6;
}

:root {
  --text-color: #333;
  --bg-color: #fff;
  --progress-bg-color: #e0e0e0;
  --progress-color: #007bff;
  --question-bg-color: #f8f9fa;
  --pattern-bg-color: #fff;
  --shadow-color: rgba(0,0,0,0.1);
  --border-color: #ddd;
  --button-bg-color: #fff;
  --button-border-color: #007bff;
  --button-text-color: #007bff;
  --button-hover-bg-color: #e9ecef;
  --button-selected-bg-color: #007bff;
  --button-selected-text-color: #fff;
  --focus-color: rgba(0,123,255,0.5);
}

body.dark-mode {
  --text-color: #f1f1f1;
  --bg-color: #121212;
  --progress-bg-color: #333;
  --progress-color: #4caf50;
  --question-bg-color: #1e1e1e;
  --pattern-bg-color: #2c2c2c;
  --shadow-color: rgba(255,255,255,0.1);
  --border-color: #444;
  --button-bg-color: #2c2c2c;
  --button-border-color: #4caf50;
  --button-text-color: #4caf50;
  --button-hover-bg-color: #3c3c3c;
  --button-selected-bg-color: #4caf50;
  --button-selected-text-color: #121212;
  --focus-color: rgba(76,175,80,0.5);
}
</style>