<template>
  <div class="result" v-if="result">
    <h2>ADHD 테스트 결과</h2>
    <div class="scores">
      <h3>ADHD 점수:</h3>
      <div v-for="(score, type) in result" :key="type" class="score-bar">
        <div class="score-label">{{ getTypeLabel(type) }}</div>
        <div class="score-value">{{ score.toFixed(1) }}%</div>
        <div class="progress-bar">
          <div class="progress" :style="{ width: `${score}%`, backgroundColor: getScoreColor(score) }"></div>
        </div>
        <div class="interpretation">{{ interpretScore(score) }}</div>
      </div>
    </div>
    <div class="interpretation">
      <h3>결과 해석:</h3>
      <p v-for="(score, type) in result" :key="type">
        <strong>{{ getTypeLabel(type) }}:</strong> {{ getTypeInterpretation(type) }}
      </p>
    </div>
    <div class="characteristics">
      <h3>ADHD 유형별 특징:</h3>
      <h4>주의력 결핍 유형:</h4>
      <ul>
        <li>집중력 유지의 어려움</li>
        <li>세부사항 놓치기</li>
        <li>지시 따르기 어려움</li>
        <li>과제 조직화 및 완료의 어려움</li>
        <li>쉽게 산만해짐</li>
      </ul>
      <h4>과잉 행동 유형:</h4>
      <ul>
        <li>과도한 신체 활동</li>
        <li>참을성 부족</li>
        <li>충동적 행동</li>
        <li>과도한 말하기</li>
        <li>차례 기다리기 어려움</li>
      </ul>
    </div>
    <p class="note">
      이 결과는 참고용이며, 정확한 진단을 위해서는 전문의와 상담하시기 바랍니다. 
      ADHD는 복잡한 신경발달장애로, 전문가의 종합적인 평가가 필요합니다.
    </p>
    <div class="button-group">
      <button @click="retakeTest">테스트 다시하기</button>
      <button @click="shareResult">결과 공유하기</button>
      <button @click="goToTestList">테스트 목록으로</button>
    </div>
  </div>
  <div v-else class="loading">
    결과를 불러오는 중입니다...
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ADHDTestResult',
  props: {
    result: Object,
  },
  methods: {
    ...mapActions(['startTest', 'resetTest']),
    interpretScore(score) {
      if (score < 40) return '정상 범위';
      if (score < 60) return '경계선';
      if (score < 80) return '중간 정도의 증상';
      return '심각한 증상';
    },
    getTypeLabel(type) {
      switch(type) {
        case 'inattentive': return '주의력 결핍';
        case 'hyperactive': return '과잉 행동';
        case 'combined': return '복합형';
        default: return type;
      }
    },
    getScoreColor(score) {
      if (score < 40) return '#4caf50';
      if (score < 60) return '#ffeb3b';
      if (score < 80) return '#ff9800';
      return '#f44336';
    },
    getTypeInterpretation(type) {
      if (!this.result) return '';
      const score = this.result[type];
      if (score < 40) return '해당 유형의 ADHD 증상이 정상 범위에 속합니다.';
      if (score < 60) return '해당 유형의 ADHD 증상이 경계선에 있습니다. 주의 깊은 관찰이 필요할 수 있습니다.';
      if (score < 80) return '해당 유형의 ADHD 증상이 중간 정도로 나타납니다. 일상생활에 상당한 영향을 줄 수 있으며, 전문가와의 상담을 고려해보시기 바랍니다.';
      return '해당 유형의 ADHD 증상이 심각하게 나타납니다. 일상생활에 큰 영향을 줄 수 있으며, 전문가의 도움을 받아 적절한 치료 방법을 찾아보시기 바랍니다.';
    },
    retakeTest() {
      this.startTest('ADHD');
    },
    shareResult() {
      const shareText = `
ADHD 테스트 결과:

주의력 결핍: ${this.result.inattentive.toFixed(1)}% (${this.interpretScore(this.result.inattentive)})
과잉 행동: ${this.result.hyperactive.toFixed(1)}% (${this.interpretScore(this.result.hyperactive)})
복합형: ${this.result.combined.toFixed(1)}% (${this.interpretScore(this.result.combined)})

주의력 결핍: ${this.getTypeInterpretation('inattentive')}
과잉 행동: ${this.getTypeInterpretation('hyperactive')}
복합형: ${this.getTypeInterpretation('combined')}

이 결과는 참고용이며, 정확한 진단을 위해서는 전문의와 상담하시기 바랍니다.

나도 테스트 해보기: ${window.location.origin}/adhd-test
      `;
      
      if (navigator.share) {
        navigator.share({
          title: 'ADHD 테스트 결과',
          text: shareText,
          url: window.location.href,
        })
          .then(() => console.log('공유 성공'))
          .catch((error) => console.log('공유 실패:', error));
      } else {
        // 공유 API를 지원하지 않는 브라우저를 위한 대체 방법
        const textarea = document.createElement('textarea');
        textarea.value = shareText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('결과가 클립보드에 복사되었습니다. 원하는 곳에 붙여넣기 하세요.');
      }
    },
    goToTestList() {
      this.resetTest();
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss">
.result {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: background-color 0.3s, color 0.3s;
}

body:not(.dark-mode) .result {
  background-color: #f8f9fa;
  color: #343a40;
}

body.dark-mode .result {
  background-color: #2c3e50;
  color: #ecf0f1;
  box-shadow: 0 0 10px rgba(255,255,255,0.1);
}

h2, h3, h4 {
  margin-top: 20px;
  transition: color 0.3s;
}

body:not(.dark-mode) h2, body:not(.dark-mode) h3, body:not(.dark-mode) h4 {
  color: #343a40;
}

body.dark-mode h2, body.dark-mode h3, body.dark-mode h4 {
  color: #ecf0f1;
}

.scores, .interpretation, .characteristics {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

body:not(.dark-mode) .scores, body:not(.dark-mode) .interpretation, body:not(.dark-mode) .characteristics {
  background-color: white;
  color: #343a40;
}

body.dark-mode .scores, body.dark-mode .interpretation, body.dark-mode .characteristics {
  background-color: #34495e;
  color: #ecf0f1;
  box-shadow: 0 2px 5px rgba(255,255,255,0.05);
}

.score-bar {
  margin-bottom: 15px;
}

.score-label, .score-value {
  display: inline-block;
  width: 50%;
  font-weight: bold;
}

.progress-bar {
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  transition: background-color 0.3s;
}

body:not(.dark-mode) .progress-bar {
  background-color: #e9ecef;
}

body.dark-mode .progress-bar {
  background-color: #2c3e50;
}

.progress {
  height: 100%;
  transition: width 0.5s ease-out;
}

.interpretation {
  margin-top: 5px;
  font-style: italic;
}

ul {
  padding-left: 20px;
}

.note {
  font-style: italic;
  margin-top: 20px;
  transition: color 0.3s;
}

body:not(.dark-mode) .note {
  color: #6c757d;
}

body.dark-mode .note {
  color: #bdc3c7;
}

.button-group {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

body:not(.dark-mode) button {
  background-color: #007bff;
  color: white;
}

body:not(.dark-mode) button:hover {
  background-color: #0056b3;
}

body.dark-mode button {
  background-color: #3498db;
  color: #ecf0f1;
}

body.dark-mode button:hover {
  background-color: #2980b9;
}

.loading {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}
</style>