<template>
  <div class="adhd-test">
    <h1>ADHD 테스트</h1>
    <div v-if="!testCompleted" class="progress-bar">
      <div class="progress" :style="{ width: `${progress}%` }"></div>
    </div>
    <p v-if="!testCompleted" class="progress-text">{{ currentQuestionIndex + 1 }} / {{ adhdQuestions.length }}</p>
    <TestQuestion 
      v-if="!testCompleted && currentQuestion"
      :question="currentQuestion"
      :answer="answers[currentQuestion.id]"
      @answer="handleAnswer"
    />
    <div v-if="!testCompleted && currentQuestion" class="navigation">
      <button @click="prevQuestion" :disabled="currentQuestionIndex === 0">이전</button>
      <button @click="nextQuestion" :disabled="!canProceed">
        {{ isLastQuestion ? '결과 보기' : '다음' }}
      </button>
    </div>
    <ADHDTestResult 
      v-if="testCompleted"
      :result="testResult"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import TestQuestion from '@/components/TestQuestion.vue';
import ADHDTestResult from '@/components/ADHDTestResult.vue';

export default {
  name: 'ADHDTest',
  components: {
    TestQuestion,
    ADHDTestResult
  },
  computed: {
    ...mapState(['currentQuestionIndex', 'answers', 'adhdQuestions', 'testCompleted']),
    ...mapGetters(['currentQuestion', 'isLastQuestion', 'testResult']),
    canProceed() {
      return this.currentQuestion && this.answers[this.currentQuestion.id] !== undefined;
    },
    progress() {
      return (this.currentQuestionIndex / this.adhdQuestions.length) * 100;
    }
  },
  methods: {
    ...mapActions(['startTest', 'answerQuestion', 'nextQuestion', 'prevQuestion']),
    handleAnswer(questionId, value) {
      this.answerQuestion({ questionId, value });
    }
  },
  created() {
    this.startTest('ADHD');
  }
}
</script>

<style scoped lang="scss">
.adhd-test {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  margin-bottom: 10px;
}

.progress {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.progress-text {
  text-align: center;
  margin-bottom: 20px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
}
</style>